import React, {
  FC,
  ReactNode,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { getRegionalTextString } from '@payaca/helpers/internationalHelper';
import { RegionalStrings } from '@payaca/types/internationalTypes';
import Input, { InputIcon, TProps as IInputProps } from './Input';
import { AccountRegions } from '@payaca/types/accountTypes';
import { TIconName } from '@payaca/untitled-icons';
import { isNullish } from '@payaca/utilities/guards';
import InputGroup from '../plInputGroup/InputGroup';
import { clstx } from '../utils';

export interface ICurrencyInputProps
  extends Omit<IInputProps, 'value' | 'onChange'> {
  value: number;
  onChange?: (value: number) => void;
  allowPennies?: boolean;
  region?: AccountRegions;
}

const CurrencyInput: FC<ICurrencyInputProps> = (props) => {
  const {
    value,
    onChange,
    onTouch,
    allowPennies = true,
    region,
    onBlur,
    onFocus,
    sizeVariant,
    inputClassName,
    ...rest
  } = props;
  const [formattedValue, setFormattedValue] = useState<any>();
  const [isFocused, setIsFocused] = useState(false);

  useEffect(() => {
    if (!isFocused) {
      // set formatted value when no focus
      const initialValue = isNullish(value)
        ? value
        : Number(value / 100).toFixed(allowPennies ? 2 : 0);
      setFormattedValue(initialValue);
    }
  }, [isFocused, value]);

  const currencySymbol = useMemo<ReactNode>(() => {
    const symbol = getRegionalTextString(
      region,
      RegionalStrings.CURRENCY_SYMBOL
    );

    let iconName: TIconName;
    switch (symbol) {
      case '£':
        iconName = 'currency-pound';
        break;
      case '$':
        iconName = 'currency-dollar';
        break;
      default:
        // Just return the text
        return (
          <span className="w-4 text-center text-base font-semibold">
            {symbol}
          </span>
        );
    }

    return <InputIcon name={iconName} />;
  }, [region]);

  return (
    <InputGroup sizeVariant={sizeVariant}>
      <InputGroup.Text>{currencySymbol}</InputGroup.Text>

      <InputGroup.Input
        type="number"
        step=".01"
        pattern="^[+-]?[0-9]*"
        value={formattedValue}
        inputClassName={clstx('pl-form-input--number', inputClassName)}
        onChange={(update) => {
          onChange?.(Math.round(Number(update) * 100));
          setFormattedValue(update || '');
        }}
        onFocus={(e) => {
          setIsFocused(true);
          // clear input to be able to type straight away
          if (formattedValue === '0.00') {
            setFormattedValue('');
          }
          onFocus?.(e);
        }}
        onBlur={(e) => {
          setIsFocused(false);
          onBlur?.(e);
        }}
        {...rest}
      />
    </InputGroup>
  );
};

export default CurrencyInput;
