import { FC, useMemo, useReducer, useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';

import { ManageableItemsList } from '@payaca/components/plManageableItemsList/ManageableItemsList';
import Button from '@payaca/components/plButton/Button';
import SearchMaterialsDrawer from '@/ui/components/searchMaterialsDrawer/SearchMaterialsDrawer';
import { EBtnSize } from '@payaca/components/plButton/useButtonClassName';
import useGetProjectMaterialsListSummaryItems, {
  MaterialPurchaseRecord,
} from '@/api/queries/project/useGetProjectMaterialListSummaryItems';
import PopulateDealMaterialsListControl from '@/ui/components/populateDealMaterialsListControl/PopulateDealMaterialsListControl';
import { useDispatch } from 'react-redux';
import { createMaterialsListMaterials } from '@payaca/store/materialsList/materialsListActions';
import * as materialsListActions from '@payaca/store/materialsList/materialsListActions';
import SkeletonLoader from '@payaca/components/plSkeletonLoader/SkeletonLoader';
import useGetProjectMaterialsListMaterials from '@/api/queries/project/useGetProjectMaterialsListMaterials';
import { RecordPurchaseModal } from './MaterialsPanel/RecordPurchaseModal';
import {
  DeleteMaterialListMaterial,
  DeleteMaterialsListMaterialModal,
} from './MaterialsPanel/DeleteMaterialsListMaterialModal';
import {
  EditMaterialsListMaterialModal,
  EditMaterialListMaterialQuantity,
} from './MaterialsPanel/EditMaterialsListMaterialModal';
import { DeleteMaterialPurchaseRecordModal } from './MaterialsPanel/DeleteMaterialPurchaseRecordModal';
import projectKeys from '@/api/queries/project/keyFactory';
import RouterTabs, {
  TabPanel,
} from '@payaca/components/plRouterTabs/RouterTabs';
import { SummaryPanel } from './MaterialsPanel/SummaryPanel';
import { ToOrderPanel } from './MaterialsPanel/ToOrderPanel';
import { OrderedPanel } from './MaterialsPanel/OrderedPanel';
import { PurchaseOrdersPanel } from './MaterialsPanel/PurchaseOrdersPanel';
import { CreatePurchaseOrderControl } from './MaterialsPanel/CreatePurchaseOrderModal';
import { HistoryPanel } from './MaterialsPanel/HistoryPanel';
import useGetProjectMaterialsListAuditLogs from '@/api/queries/project/useGetProjectMaterialsListAuditLogs';
import {
  useAuditLogsInputPaginationState,
  usePaginationState,
  useSummaryItemsInputPaginationState,
} from './MaterialsPanel/materialsPanelHooks';
import BuildMaterialsListSidebar from '@/ui/components/buildMaterialsListSidebar/BuildMaterialsListSidebar';
import useUpdateMaterialsListMaterials from '@/api/mutations/materialsList/useUpdateMaterialsListMaterials';

interface Props {
  dealId: number;
}

const PAGE_SIZE = 100; // Setting really high until we have search + filtering

export const MaterialsPanel: FC<Props> = ({ dealId }: Props) => {
  const [state, dispatch] = useReducer(modalReducer, { activeModal: null });

  const toOrderInputPaginationState = useSummaryItemsInputPaginationState(
    PAGE_SIZE,
    { statuses: ['TO_ORDER', 'ORDER_DRAFTED'] }
  );
  const orderedInputPaginationState = useSummaryItemsInputPaginationState(
    PAGE_SIZE,
    { statuses: ['ORDERED', 'PURCHASED'] }
  );
  const summaryPaginationState = usePaginationState(PAGE_SIZE);
  const auditLogsInputPaginationState =
    useAuditLogsInputPaginationState(PAGE_SIZE);

  const {
    materialsListOrderSummaryItems: toOrderItems,
    refetch: toOrderRefetch,
  } = useGetProjectMaterialsListSummaryItems(
    dealId,
    {
      searchTerm: toOrderInputPaginationState.input.searchTerm,
      statuses: toOrderInputPaginationState.input.statuses,
    },
    {
      limit: toOrderInputPaginationState.pagination.limit,
      offset: toOrderInputPaginationState.pagination.offset,
    }
  );
  const {
    materialsListOrderSummaryItems: orderedItems,
    refetch: orderedRefetch,
  } = useGetProjectMaterialsListSummaryItems(
    dealId,
    {
      searchTerm: orderedInputPaginationState.input.searchTerm,
      statuses: orderedInputPaginationState.input.statuses,
    },
    {
      limit: orderedInputPaginationState.pagination.limit,
      offset: orderedInputPaginationState.pagination.offset,
    }
  );
  const { auditLogs, refetch: auditLogsRefetch } =
    useGetProjectMaterialsListAuditLogs(
      dealId,
      {
        searchTerm: auditLogsInputPaginationState.input.searchTerm,
        actingUserIds: auditLogsInputPaginationState.input.actingUserIds,
      },
      {
        limit: auditLogsInputPaginationState.pagination.limit,
        offset: auditLogsInputPaginationState.pagination.offset,
      }
    );

  const {
    data,
    materialsListMaterials,
    refetch: mlmRefetch,
  } = useGetProjectMaterialsListMaterials(dealId, {
    limit: summaryPaginationState.limit,
    offset: summaryPaginationState.offset,
  });

  const materialsListId =
    (data?.project && +data.project.materialsList.id) || null;

  const reduxDispatch = useDispatch();
  const queryClient = useQueryClient();

  const { mutateAsync: onAddMaterials } = useUpdateMaterialsListMaterials({
    onSettled: () => {
      if (materialsListId) {
        reduxDispatch(
          materialsListActions.requestGetMaterialsListWithRelatedEntities(
            materialsListId
          )
        );
      }

      void queryClient.invalidateQueries(projectKeys.materialsList(dealId));
      void queryClient.invalidateQueries(projectKeys.profitBreakdown(dealId));
    },
  });

  if (!materialsListMaterials || !materialsListId) {
    return <SkeletonLoader className="h-20 rounded-lg" />;
  }

  const selectedMaterialsListMaterial =
    state.activeModal === 'recordPurchase' &&
    toOrderItems?.items.find(
      (item) => item.materialsListMaterial.id === state.materialListMaterialId
    )?.materialsListMaterial;

  const tabPanels: TabPanel[] = [
    {
      label: 'Summary',
      slug: 'summary',
      render: () =>
        materialsListMaterials ? (
          <SummaryPanel
            projectId={dealId}
            summaryItems={materialsListMaterials}
            onPageChange={summaryPaginationState.setCurrentPage}
            onRequestDeleteMaterialsListMaterial={(mlm) => {
              dispatch({
                type: 'openDeleteMaterialsListMaterial',
                payload: { materialsListMaterial: mlm },
              });
            }}
            onRequestEditMaterialsListMaterial={(mlm) => {
              dispatch({
                type: 'openEditMaterialsListMaterial',
                payload: { materialsListMaterial: mlm },
              });
            }}
          />
        ) : null,
    },
    {
      label: 'To order',
      slug: 'to-order',
      render: () => (
        <ToOrderPanel
          inputPaginationState={toOrderInputPaginationState}
          summaryItems={toOrderItems}
          projectId={dealId}
          onRequestEditMaterialsListMaterial={(mlm) => {
            dispatch({
              type: 'openEditMaterialsListMaterial',
              payload: { materialsListMaterial: mlm },
            });
          }}
          onRequestDeleteMaterialsListMaterial={(mlm) => {
            dispatch({
              type: 'openDeleteMaterialsListMaterial',
              payload: { materialsListMaterial: mlm },
            });
          }}
          onRecordPurchase={(materialListMaterialId) =>
            dispatch({
              type: 'openRecordPurchase',
              payload: { materialListMaterialId },
            })
          }
        />
      ),
    },
    {
      label: 'Ordered',
      slug: 'ordered',
      render: () => (
        <OrderedPanel
          summaryItems={orderedItems}
          projectId={dealId}
          inputPaginationState={orderedInputPaginationState}
          onRequestDeleteMaterialPurchaseRecord={(row) => {
            if (row.__typename == 'MaterialsListOrderSummaryPurchasedItem') {
              dispatch({
                type: 'openDeleteMaterialPurchaseRecord',
                payload: {
                  materialPurchaseRecord: row.materialPurchaseRecord,
                },
              });
            }
          }}
        />
      ),
    },
    {
      label: 'Purchase Orders',
      slug: 'purchase-orders',
      render: () => (
        <PurchaseOrdersPanel
          projectId={dealId}
          materialsListId={materialsListId}
        />
      ),
    },
    {
      label: 'History',
      slug: 'history',
      render: () => (
        <HistoryPanel
          projectId={dealId}
          inputPaginationState={auditLogsInputPaginationState}
          auditLogs={auditLogs}
        />
      ),
    },
  ];

  return (
    <ManageableItemsList>
      <ManageableItemsList.HeaderBar
        heading="Materials"
        buttons={
          <>
            <PopulateDealMaterialsListControl
              dealId={dealId}
              onSyncComplete={() => {
                void queryClient.invalidateQueries(
                  projectKeys.materialsListMaterials(dealId)
                );
                void queryClient.invalidateQueries(
                  projectKeys.materialsListAuditLogs(dealId)
                );
                void queryClient.invalidateQueries(
                  projectKeys.materialsListSummaryItems(dealId)
                );
              }}
            />
            <CreatePurchaseOrderControl
              materialsListId={materialsListId}
              projectId={dealId}
            />
            <Button
              onClick={() =>
                dispatch({ type: 'openCreateMaterialsListMaterial' })
              }
              size={EBtnSize.Small}
            >
              Add Materials
            </Button>
          </>
        }
      />
      <RouterTabs panels={tabPanels}>
        <RouterTabs.Nav
          defaultTabSlug="summary"
          className="p-2 px-4"
          onChange={() => {
            summaryPaginationState.setCurrentPage(1);
            toOrderInputPaginationState.reset();
            orderedInputPaginationState.reset();
            auditLogsInputPaginationState.reset();
          }}
        />

        <RouterTabs.Panels />
      </RouterTabs>

      <BuildMaterialsListSidebar
        title="Add Materials"
        showPriceIncludingTax={false}
        emptyStatePromptText="Add required Materials"
        primaryActionText="Add to Project"
        warnUserContent={{
          body: "You have selected Materials that you haven't added to the Project.",
        }}
        isOpen={state.activeModal === 'createMaterialsListMaterial'}
        onClose={() => dispatch({ type: 'closeModal' })}
        onAddMaterials={async (materials) => {
          await onAddMaterials({
            materialsListId: materialsListId.toString(),
            materialsListMaterials: materials.map((material) => ({
              materialId: material.id,
              quantityChange: {
                relative: material.quantity,
              },
            })),
          });
        }}
      />
      {selectedMaterialsListMaterial && (
        <RecordPurchaseModal
          materialsListId={materialsListId}
          isOpen
          materialAndSuppliers={selectedMaterialsListMaterial}
          onClose={() => dispatch({ type: 'closeModal' })}
          onSuccess={() => {
            void mlmRefetch();
            void toOrderRefetch();
            void orderedRefetch();
            dispatch({ type: 'closeModal' });
          }}
        />
      )}
      {state.activeModal === 'deleteMateralsListMaterial' && (
        <DeleteMaterialsListMaterialModal
          isOpen={true}
          onClose={() => dispatch({ type: 'closeModal' })}
          materialsListMaterial={state.materialsListMaterial}
          onDeleteSuccess={() => {
            void mlmRefetch();
            void toOrderRefetch();
            void auditLogsRefetch();
            dispatch({ type: 'closeModal' });
          }}
        />
      )}
      {state.activeModal === 'editMateralsListMaterial' && (
        <EditMaterialsListMaterialModal
          isOpen={true}
          onClose={() => dispatch({ type: 'closeModal' })}
          materialsListMaterial={state.materialsListMaterial}
          onPersistSuccess={() => {
            void mlmRefetch();
            void toOrderRefetch();
            void auditLogsRefetch();
            dispatch({ type: 'closeModal' });
          }}
        />
      )}
      {state.activeModal === 'deleteMaterialPurchaseRecord' && (
        <DeleteMaterialPurchaseRecordModal
          isOpen={true}
          onClose={() => dispatch({ type: 'closeModal' })}
          materialPurchaseRecord={state.materialPurchaseRecord}
          onDeleteSuccess={() => {
            void mlmRefetch();
            void toOrderRefetch();
            void orderedRefetch();
            dispatch({ type: 'closeModal' });
          }}
        />
      )}
    </ManageableItemsList>
  );
};

type ModalState =
  | {
      activeModal: 'recordPurchase';
      materialListMaterialId: string;
    }
  | {
      // Ok so its not strictly a modal but it's a modal-like thing
      activeModal: 'createMaterialsListMaterial';
    }
  | {
      activeModal: 'deleteMateralsListMaterial';
      materialsListMaterial: DeleteMaterialListMaterial;
    }
  | {
      activeModal: 'deleteMaterialPurchaseRecord';
      materialPurchaseRecord: MaterialPurchaseRecord;
    }
  | {
      activeModal: 'editMateralsListMaterial';
      materialsListMaterial: EditMaterialListMaterialQuantity;
    }
  | {
      activeModal: null;
    };

type ModalAction =
  | {
      type: 'openRecordPurchase';
      payload: {
        materialListMaterialId: string;
      };
    }
  | {
      type: 'openCreateMaterialsListMaterial';
    }
  | {
      type: 'openDeleteMaterialsListMaterial';
      payload: {
        materialsListMaterial: DeleteMaterialListMaterial;
      };
    }
  | {
      type: 'openDeleteMaterialPurchaseRecord';
      payload: {
        materialPurchaseRecord: MaterialPurchaseRecord;
      };
    }
  | {
      type: 'openEditMaterialsListMaterial';
      payload: {
        materialsListMaterial: EditMaterialListMaterialQuantity;
      };
    }
  | {
      type: 'closeModal';
    };

const modalReducer = (state: ModalState, action: ModalAction): ModalState => {
  switch (action.type) {
    case 'openCreateMaterialsListMaterial':
      return {
        ...state,
        activeModal: 'createMaterialsListMaterial',
      };
    case 'openRecordPurchase':
      return {
        ...state,
        activeModal: 'recordPurchase',
        materialListMaterialId: action.payload.materialListMaterialId,
      };
    case 'openDeleteMaterialsListMaterial':
      return {
        ...state,
        activeModal: 'deleteMateralsListMaterial',
        materialsListMaterial: action.payload.materialsListMaterial,
      };
    case 'openDeleteMaterialPurchaseRecord':
      return {
        ...state,
        activeModal: 'deleteMaterialPurchaseRecord',
        materialPurchaseRecord: action.payload.materialPurchaseRecord,
      };
    case 'openEditMaterialsListMaterial':
      return {
        ...state,
        activeModal: 'editMateralsListMaterial',
        materialsListMaterial: action.payload.materialsListMaterial,
      };
    case 'closeModal':
      return {
        activeModal: null,
      };
    default:
      return state;
  }
};
