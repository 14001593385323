import React, { FC, useContext, useMemo } from 'react';
import { FieldContext } from '../plField/Field';
import { Switch as HeadlessSwitch } from '@headlessui/react';

export enum SwitchSizeVariant {
  SM = 'sm',
  MD = 'md',
  LG = 'lg',
}

type Props = {
  onChange?: (value: boolean) => void;
  value?: boolean;
  disabled?: boolean;
  sizeVariant?: SwitchSizeVariant;
};

const Switch: FC<Props> = ({
  onChange,
  value,
  disabled,
  sizeVariant = SwitchSizeVariant.MD,
}) => {
  const { id, name, validationState } = useContext(FieldContext);

  const { bgColour, toggleBgColour } = useMemo(() => {
    if (validationState && !validationState.isValid) {
      return { bgColour: 'bg-red-200', toggleBgColour: 'bg-red-500' };
    } else if (value) {
      return { bgColour: 'bg-none bg-blue-200', toggleBgColour: 'bg-blue-600' };
    } else {
      return { bgColour: 'bg-gray-100', toggleBgColour: 'bg-white' };
    }
  }, [validationState?.isValid, value]);

  const { sizeClasses, toggleSizeClasses, checkedClasses } = useMemo(() => {
    switch (sizeVariant) {
      case SwitchSizeVariant.SM:
        return {
          sizeClasses: 'w-11 h-6',
          toggleSizeClasses: 'w-5 h-5',
          checkedClasses: 'translate-x-5',
        };

      case SwitchSizeVariant.MD:
        return {
          sizeClasses: 'w-[4.3rem] h-7',
          toggleSizeClasses: 'w-6 h-6',
          checkedClasses: 'translate-x-7',
        };

      case SwitchSizeVariant.LG:
        return {
          sizeClasses: 'w-[5.67rem] h-9',
          toggleSizeClasses: 'w-8 h-8',
          checkedClasses: 'translate-x-9',
        };
    }
  }, [sizeVariant]);

  return (
    <HeadlessSwitch
      id={id}
      name={name}
      checked={value}
      onChange={onChange}
      disabled={!!disabled}
      className={`relative text-left ${sizeClasses} p-0 ${bgColour} cursor-pointer appearance-none rounded-full border-2 border-transparent ring-1 ring-transparent ring-offset-white transition-colors duration-200 ease-in-out focus:border-blue-600 focus:outline-none focus:ring-blue-600 dark:bg-gray-700 dark:checked:bg-blue-600 dark:focus:ring-offset-gray-800`}
    >
      {({ checked }) => {
        return (
          <span
            className={`inline-block ${toggleSizeClasses} ${toggleBgColour} ${
              checked ? checkedClasses : 'translate-x-0'
            }  transform rounded-full shadow ring-0 transition duration-200 ease-in-out`}
          />
        );
      }}
    </HeadlessSwitch>
  );
};

export default Switch;
