import React, { FC, useState } from 'react';
import { useScroll } from 'react-use';

import { useGetInfiniteMaterials } from '@/api/queries/materials/useGetMaterials';
import { GetMaterialsInput, MaterialsQuery } from '@/gql/graphql';
import InfiniteList from '@payaca/components/infiniteList/InfiniteList';
import { clstx } from '@payaca/components/utils';
import SkeletonLoader from '@payaca/components/plSkeletonLoader/SkeletonLoader';
import Conditional from '@payaca/components/conditional/Conditional';
import { singularPlural } from '@payaca/utilities/stringUtilities';
import MaterialCard from '@payaca/components/materialCard/MaterialCard';
import useGetSuppliers from '@/api/queries/suppliers/useGetSuppliers';
import useGetMaterialCategories from '@/api/queries/materials/useGetMaterialCategories';
import usePreferredNumberFormat from '@/hooks/usePreferredNumberFormat';

type Material = MaterialsQuery['materials']['items'][number];

export interface IProps {
  onAddMaterial?: () => void;
  onMaterialClick?: (material: Material) => void;
  disabledMaterials?: Material['id'][];
  showPriceIncludingTax?: boolean;
}

const InfiniteMaterialsList: FC<IProps> = (props) => {
  const {
    onAddMaterial,
    onMaterialClick,
    disabledMaterials = [],
    showPriceIncludingTax = true,
  } = props;

  const [scrollRef, setScrollRef] = useState<HTMLDivElement | null>(null);
  const { y: scrollY } = useScroll({ current: scrollRef });

  const [getMaterialsInput, setGetMaterialsInput] = useState<GetMaterialsInput>(
    {}
  );

  const compactNumberFormat = usePreferredNumberFormat({
    notation: 'compact',
    compactDisplay: 'short',
  });

  const {
    data: materials,
    isLoading,
    hasNextPage,
    fetchNextPage,
  } = useGetInfiniteMaterials(getMaterialsInput);

  const { suppliers } = useGetSuppliers({}, { limit: 100 });
  const { materialCategories } = useGetMaterialCategories();

  const suppliersFilters =
    suppliers?.items.map((supplier) => ({
      id: supplier.id,
      label: supplier.name,
      selected: getMaterialsInput.suppliers?.some((i) => i === supplier.id),
      onClick: (selected: boolean) => {
        setGetMaterialsInput((state) => ({
          ...state,
          suppliers: selected
            ? [...(state.suppliers || []), supplier.id]
            : state.suppliers?.filter((i) => i !== supplier.id),
        }));
      },
    })) || [];

  const categoriesFilters =
    materialCategories?.map((category) => ({
      id: category.id,
      label: category.name,
      selected: getMaterialsInput.categories?.some((i) => i === category.id),
      onClick: (selected: boolean) => {
        setGetMaterialsInput((state) => ({
          ...state,
          categories: selected
            ? [...(state.categories || []), category.id]
            : state.categories?.filter((i) => i !== category.id),
        }));
      },
    })) || [];

  const totalMaterialsFound = materials?.pages[0]?.materials.totalCount || 0;

  return (
    <InfiniteList ref={setScrollRef}>
      <InfiniteList.Header className={clstx(scrollY > 0 && 'shadow')}>
        <InfiniteList.Search
          placeholder="Search materials"
          onChangeTimeout={(value) => {
            setGetMaterialsInput((s) => ({
              ...s,
              searchTerm: value,
            }));
          }}
        />

        <InfiniteList.Actionbar>
          <InfiniteList.Filter filters={categoriesFilters} name="Category" />

          <InfiniteList.Filter filters={suppliersFilters} name="Suppliers" />

          <Conditional condition={!!onAddMaterial}>
            <InfiniteList.PrimaryAction
              className="flex-auto"
              onClick={onAddMaterial}
            >
              Create new Material
            </InfiniteList.PrimaryAction>
          </Conditional>
        </InfiniteList.Actionbar>
      </InfiniteList.Header>

      <InfiniteList.List
        skeletonItem={<SkeletonLoader.MaterialCard />}
        isLoading={isLoading}
        prefix={
          <Conditional condition={!isLoading && totalMaterialsFound > 0}>
            <p className="supporting-body text-right">
              {singularPlural(
                totalMaterialsFound,
                'material found',
                'materials found',
                {
                  lengthFormatted:
                    compactNumberFormat.format(totalMaterialsFound),
                }
              )}
            </p>
          </Conditional>
        }
        emptyStateText="No materials found"
        numOfSkeletonLoaders={12}
        canLoadMore={hasNextPage}
        onLoadMore={() => {
          if (hasNextPage) {
            void fetchNextPage();
          }
        }}
        items={
          materials?.pages.map((m) => m.materials.items.map((i) => i)).flat() ||
          []
        }
        item={(material) => {
          return (
            <MaterialCard
              key={material.id}
              name={material.name}
              price={
                showPriceIncludingTax
                  ? material.suppliedBy[0]?.price.unitPrice
                  : material.suppliedBy[0]?.price.unitPriceExcTax
              }
              thumbnailUrl={material.thumbnailUrl || ''}
              suppliers={material.suppliedBy.map((i) => i.supplier.name)}
              actionOnClick={() => {
                onMaterialClick?.(material);
              }}
              actionDisabled={disabledMaterials.includes(material.id)}
            />
          );
        }}
      />
    </InfiniteList>
  );
};

export default InfiniteMaterialsList;
