import React, { FC, useEffect, useState } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useQueryClient } from '@tanstack/react-query';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';

import Sidebar, {
  Props as SidebarProps,
} from '@payaca/components/plSidebar/Sidebar';
import { Transition } from '@headlessui/react';
import Field from '@payaca/components/plField/Field';
import Input from '@payaca/components/plInput/RawInput';
import BuildMaterialsListSidebar, {
  MaterialState,
} from '@/ui/components/buildMaterialsListSidebar/BuildMaterialsListSidebar';
import MaterialCard from '@payaca/components/materialCard/MaterialCard';
import EditLineItemPrice from '@/ui/pages/listedItemsPage/components/EditLineItemPrice';
import Button from '@payaca/components/plButton/Button';
import useCreateLineItem from '@/api/mutations/lineItems/useCreateLineItem';
import useGetLineItem from '@/api/queries/lineItems/useGetLineItem';
import useUpdateLineItemMaterials from '@/api/mutations/lineItems/useUpdateLineItemMaterials';
import {
  calculateMarkupChange,
  calculatePredictedMaterialCosts,
  MarkupType,
} from '@/ui/pages/listedItemsPage/components/utils';
import useUpdateLineItem from '@/api/mutations/lineItems/useUpdateLineItem';
import { UpdateLineItemInput } from '@/gql/graphql';
import useGetMyTaxRates from '@/api/queries/me/useGetMyTaxRates';
import lineItemKeys from '@/api/queries/lineItems/keyFactory';
import FileInput from '@payaca/components/plInput/FileInput';
import { useDispatch } from 'react-redux';
import * as lineItemActions from '@payaca/store/lineItems/lineItemsActions';
import { InputStyleVariant } from '@payaca/components/inputWrapper/InputWrapper';
import TextareaFieldFormatter, {
  ToolbarColourVariant,
} from '@payaca/components/textareaField/TextareaFieldFormatter';
import List from '@payaca/components/list/List';
import {
  EBtnSize,
  EBtnVariant,
} from '@payaca/components/plButton/useButtonClassName';
import Conditional from '@payaca/components/conditional/Conditional';
import EmptyState from '@payaca/components/plEmptyState/EmptyState';
import Card, { CardSizeVariant } from '@payaca/components/plCard/Card';
import { getAcceptedFileTypes } from '@payaca/helpers/fileHelper';

export type TFormState = {
  thumbnail?: { file?: File; preview: string };
  reference: string;
  description: string;
  taxRateId: string;
  cis: string;
  predictedMaterialCost?: number;
  markupTotals: {
    markupType: MarkupType;
    profitPercentage: number;
    profitAmount: number;
    price: number;
  };
  autoUpdatePrice: boolean;
  materials: Pick<
    MaterialState,
    'id' | 'name' | 'thumbnailUrl' | 'suppliedBy' | 'quantity'
  >[];
};

const FORM_SCHEMA = z.object({
  thumbnail: z
    .object({
      file: z.any(),
      preview: z.string(),
    })
    .optional(),
  reference: z.string().optional(),
  description: z.string().min(1),
  taxRateId: z.string(),
  cis: z.string(),
  predictedMaterialCost: z.number().optional(),
  markupTotals: z.object({
    markupType: z.string(),
    profitPercentage: z.number(),
    profitAmount: z.number(),
    price: z.number(),
  }),
  autoUpdatePrice: z.boolean(),
  materials: z.array(z.any()),
});

const AddEditItemSidebarContent: FC<{
  lineItemId?: string;
  isLinkMaterialsSidebarOpen: boolean;
  setIsLinkMaterialSidebarOpen: (state: boolean) => void;
  setIsDirty: (isDirty: boolean) => void;
  onSaveSuccess?: () => void;
}> = (props) => {
  const {
    lineItemId,
    isLinkMaterialsSidebarOpen,
    setIsLinkMaterialSidebarOpen,
    onSaveSuccess,
    setIsDirty,
  } = props;

  const isEditLineItem = !!lineItemId;

  const [isSaving, setIsSaving] = useState(false);

  const { defaultTaxRate } = useGetMyTaxRates();

  const formMethods = useForm<TFormState>({
    resolver: zodResolver(FORM_SCHEMA),
    defaultValues: {
      reference: '',
      description: '',
      taxRateId: defaultTaxRate?.id || '',
      cis: 'no',
      markupTotals: {
        markupType: 'price',
        profitPercentage: 0,
        profitAmount: 0,
        price: 0,
      },
      materials: [],
      autoUpdatePrice: true,
    },
  });

  // Annoying to have to cascade this up.
  // But we need to reset the form state when the user closes the sidebar
  // and the best way to do that is for the `useForm` hook to unmount and
  // mount each time the sidebar opens
  // (meaning the useForm hook can't be in the parent component)
  useEffect(() => {
    setIsDirty(formMethods.formState.isDirty);
  }, [formMethods.formState.isDirty]);

  /**
   * Queries
   */
  const queryClient = useQueryClient();
  const { data: originalLineItemData } = useGetLineItem(
    {
      lineItemId,
    },
    {
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        const predictedMaterialCosts = calculatePredictedMaterialCosts(
          data.lineItem.materials.map((material) => ({
            materialQuantity: material.materialQuantity,
            // The backend orders the suppliers by 'preferred supplier' or lowest price
            price:
              material.material.suppliedBy[0]?.price.unitPriceExcTax.value || 0,
          }))
        );

        let markupTotals: TFormState['markupTotals'];
        if (typeof data.lineItem.price?.markupPercentage === 'number') {
          markupTotals = {
            markupType: 'profitPercentage',
            ...calculateMarkupChange(
              'profitPercentage',
              data.lineItem.price.markupPercentage,
              predictedMaterialCosts
            ),
          };
        } else if (data.lineItem.price?.markupAmount) {
          markupTotals = {
            markupType: 'profitAmount',
            ...calculateMarkupChange(
              'profitAmount',
              data.lineItem.price.markupAmount.value,
              predictedMaterialCosts
            ),
          };
        } else {
          markupTotals = {
            markupType: 'price',
            ...calculateMarkupChange(
              'price',
              data.lineItem.price?.unitPriceExcTax.value || 0,
              predictedMaterialCosts
            ),
          };
        }

        formMethods.reset({
          thumbnail: data.lineItem.thumbnailUrl
            ? { preview: data.lineItem.thumbnailUrl }
            : undefined,
          reference: data.lineItem.name || '',
          description: data.lineItem.description || '',
          taxRateId: data.lineItem.price?.taxRate?.id || '',
          cis:
            typeof data.lineItem.price?.cisDeductionRate === 'number'
              ? 'yes'
              : 'no',
          markupTotals,
          predictedMaterialCost: predictedMaterialCosts,
          materials: data.lineItem.materials.map((i) => ({
            id: i.material.id,
            name: i.material.name,
            thumbnailUrl: i.material.thumbnailUrl,
            suppliedBy: i.material.suppliedBy,
            quantity: i.materialQuantity,
          })),
          autoUpdatePrice: true,
        });
      },
    }
  );

  /**
   * Mutations
   */
  const { mutateAsync: createLineItemMutation } = useCreateLineItem();
  const { mutateAsync: updateLineItemMutation } = useUpdateLineItem();
  const { mutateAsync: updateLineItemMaterialsMutation } =
    useUpdateLineItemMaterials();

  /**
   * Redux
   */
  const reduxDispatch = useDispatch();

  const linkedMaterials = formMethods.watch('materials');
  const { markupType: markupTotalsType } = formMethods.watch('markupTotals');

  const reCalculateMarkupTotals = (
    newLinkedMaterials: TFormState['materials']
  ) => {
    const predictedMaterialCosts = calculatePredictedMaterialCosts(
      newLinkedMaterials.map((material) => ({
        materialQuantity: material.quantity,
        // The backend orders the suppliers by 'preferred supplier' or lowest price
        price: material.suppliedBy[0]?.price.unitPriceExcTax.value || 0,
      }))
    );

    formMethods.setValue('materials', newLinkedMaterials, {
      shouldDirty: true,
    });

    formMethods.setValue(
      'markupTotals',
      {
        markupType: markupTotalsType,
        ...calculateMarkupChange(
          markupTotalsType,
          formMethods.getValues(`markupTotals.${markupTotalsType}`),
          predictedMaterialCosts
        ),
      },
      {
        shouldDirty: true,
      }
    );

    formMethods.setValue('predictedMaterialCost', predictedMaterialCosts, {
      shouldDirty: true,
    });
  };

  const onSubmit = async (state: TFormState) => {
    setIsSaving(true);

    const price: UpdateLineItemInput['price'] = {};
    switch (true) {
      case state.markupTotals.markupType === 'profitPercentage' &&
        state.autoUpdatePrice:
        price['markupPercentage'] = state.markupTotals.profitPercentage;
        break;
      case state.markupTotals.markupType === 'profitAmount' &&
        state.autoUpdatePrice:
        price['markupAmount'] = state.markupTotals.profitAmount;
        break;
      default:
        price['unitPriceExcTax'] = state.markupTotals.price;
        break;
    }

    let newOrCurrentLineItemId = lineItemId;
    if (isEditLineItem) {
      const removeLinkedMaterials =
        originalLineItemData?.lineItem.materials.filter((material) => {
          return !state.materials.some(
            (newMaterial) => newMaterial.id === material.material.id
          );
        }) || [];

      await updateLineItemMaterialsMutation({
        lineItemId,
        lineItemMaterials: [
          ...removeLinkedMaterials.map((material) => ({
            materialId: material.material.id,
            quantityChange: {
              absolute: 0,
            },
          })),
          ...state.materials.map((material) => ({
            materialId: material.id,
            quantityChange: {
              absolute: material.quantity,
            },
          })),
        ],
      });

      await updateLineItemMutation({
        id: lineItemId,
        name: state.reference,
        description: state.description,
        price,
        taxRateId: state.taxRateId,
      });
    } else {
      const { createLineItem } = await createLineItemMutation({
        name: state.reference,
        description: state.description,
        price,
        taxRateId: state.taxRateId,
        materials: state.materials.map((material) => ({
          materialId: material.id,
          quantityChange: {
            absolute: material.quantity,
          },
        })),
      });

      newOrCurrentLineItemId = createLineItem.id;
    }

    // Any attachments to upload?
    if (state.thumbnail?.file) {
      await new Promise<void>((resolve, reject) => {
        reduxDispatch(
          lineItemActions.requestCreateUpdateLineItemAttachments(
            Number(newOrCurrentLineItemId),
            [
              {
                file: state.thumbnail!.file!,
                fileName: state.thumbnail!.file!.name,
              },
            ],
            () => {
              resolve();
            },
            (e) => {
              reject(new Error(e));
            }
          )
        );
      });
    }

    await queryClient.invalidateQueries({
      queryKey: lineItemKeys.lineItems(),
    });

    setIsSaving(false);
    onSaveSuccess?.();
  };

  const handleThumbnailDelete = async () => {
    formMethods.setValue('thumbnail', undefined, {
      shouldDirty: true,
    });

    if (lineItemId) {
      await new Promise<void>((resolve, reject) => {
        reduxDispatch(
          lineItemActions.requestCreateUpdateLineItemAttachments(
            Number(lineItemId),
            // that's correct, we're sending an empty array to delete the attachment 🤢
            [],
            () => {
              resolve();
            },
            (e) => {
              reject(new Error(e));
            }
          )
        );
      });
    }
  };

  const numOfLinkedMaterials = linkedMaterials.reduce((acc, linkedMaterial) => {
    return acc + linkedMaterial.quantity;
  }, 0);

  return (
    <>
      <div className="grid h-full grid-cols-2">
        <div className="bg-gray-50 p-3.5">
          <FormProvider {...formMethods}>
            <form
              className="flex h-full flex-col gap-4"
              onSubmit={formMethods.handleSubmit(onSubmit)}
            >
              <Field
                validationState={
                  formMethods.formState.errors.reference
                    ? {
                        isValid: false,
                        validationMessages: ['Reference is required'],
                      }
                    : undefined
                }
              >
                <Field.Label>Internal reference</Field.Label>

                <Input {...formMethods.register('reference')} />
              </Field>

              <Field
                validationState={
                  formMethods.formState.errors.description
                    ? {
                        isValid: false,
                        validationMessages: ['Title & Description is required'],
                      }
                    : undefined
                }
              >
                <Field.Label>Title & Description</Field.Label>

                <Controller
                  name="description"
                  render={({
                    field: { onChange, onBlur, value, ref, name },
                  }) => {
                    return (
                      <TextareaFieldFormatter
                        styleVariant={InputStyleVariant.STANDARD}
                        value={value.length ? value : undefined}
                        name={name}
                        onChange={(newState) => {
                          if (newState.description !== value) {
                            // Avoid dirtying the state when the user hasn't made any changes
                            onChange(newState.description);
                          }
                        }}
                        toolbarColourVariant={ToolbarColourVariant.WHITE}
                      />
                    );
                  }}
                />
              </Field>

              <EditLineItemPrice />

              <Card sizeVariant={CardSizeVariant.SM}>
                <Card.Body>
                  <Field>
                    <Field.Label>Image</Field.Label>

                    <Controller
                      name="thumbnail"
                      render={({ field: { onChange, value } }) => {
                        return (
                          <FileInput
                            label="Thumbnail"
                            url={value?.preview}
                            onDrop={(file) => {
                              onChange({
                                file,
                                preview: URL.createObjectURL(file),
                              });
                            }}
                            onDelete={handleThumbnailDelete}
                            dropzoneOptions={{
                              accept: getAcceptedFileTypes(['image']),
                            }}
                          />
                        );
                      }}
                    />
                  </Field>
                </Card.Body>
              </Card>

              <Button
                className="mt-auto w-full"
                type="submit"
                disabled={isSaving || !formMethods.formState.isDirty}
                isProcessing={isSaving}
              >
                {isEditLineItem ? 'Update Item' : 'Create Item'}
              </Button>
            </form>
          </FormProvider>
        </div>
        <div className="flex flex-col overflow-auto p-3.5">
          <List
            items={linkedMaterials}
            prefix={
              <>
                <h4>Linked Materials ({numOfLinkedMaterials})</h4>

                <Conditional condition={numOfLinkedMaterials > 0}>
                  <Button
                    className="w-full"
                    variant={EBtnVariant.Outline}
                    size={EBtnSize.Small}
                    onClick={() => setIsLinkMaterialSidebarOpen(true)}
                  >
                    Link Materials to Item
                  </Button>
                </Conditional>
              </>
            }
            item={(material) => {
              return (
                // todo: move this transition inside the MaterialCard component
                <Transition
                  key={material.id}
                  as="div"
                  appear
                  unmount
                  show={material.quantity > 0}
                  enter="transition duration-300"
                  enterFrom="opacity-0 -translate-x-full"
                  enterTo="opacity-100 translate-x-0"
                  leave="transition duration-300"
                  leaveFrom="opacity-100 translate-x-0"
                  leaveTo="opacity-0 -translate-x-full"
                  afterLeave={() => {
                    reCalculateMarkupTotals(
                      linkedMaterials.filter((i) => i.quantity > 0)
                    );
                  }}
                >
                  <MaterialCard
                    name={material.name}
                    price={material.suppliedBy[0]?.price.unitPriceExcTax}
                    thumbnailUrl={material.thumbnailUrl || ''}
                    quantity={material.quantity}
                    suppliers={material.suppliedBy.map((i) => i.supplier.name)}
                    onChangeQuantity={(newQuantity) => {
                      reCalculateMarkupTotals(
                        linkedMaterials.map((i) =>
                          i.id === material.id
                            ? { ...i, quantity: newQuantity }
                            : i
                        )
                      );
                    }}
                  />
                </Transition>
              );
            }}
          />

          <Conditional condition={numOfLinkedMaterials === 0}>
            <div className="my-auto text-center">
              <EmptyState
                iconName="link-broken-01.3"
                text="No linked Materials"
              />

              <Button
                className="mt-3.5"
                variant={EBtnVariant.Outline}
                size={EBtnSize.Small}
                onClick={() => setIsLinkMaterialSidebarOpen(true)}
              >
                Link Materials to Item
              </Button>
            </div>
          </Conditional>
        </div>
      </div>

      <BuildMaterialsListSidebar
        title="Link Materials"
        showPriceIncludingTax={false}
        emptyStatePromptText="Link Materials to Item"
        primaryActionText="Link Materials to Item"
        warnUserContent={{
          body: "You have selected Materials that you haven't linked to the Item.",
        }}
        zIndexLevel={2}
        onAddMaterials={(incomingMaterials) => {
          const merged = new Map(linkedMaterials.map((i) => [i['id'], i]));

          // Add or merge quantities with incoming materials
          incomingMaterials.forEach((i) => {
            merged.set(i['id'], {
              ...merged.get(i['id']),
              ...i,
              quantity: (merged.get(i['id'])?.quantity || 0) + i.quantity,
            });
          });

          reCalculateMarkupTotals(Array.from(merged.values()));

          return Promise.resolve();
        }}
        isOpen={isLinkMaterialsSidebarOpen}
        onClose={() => setIsLinkMaterialSidebarOpen(false)}
      />
    </>
  );
};

const AddEditItemSidebar: FC<
  Omit<SidebarProps, 'size' | 'title' | 'behind'> & { lineItemId?: string }
> = (props) => {
  const { lineItemId, isOpen, onClose, ...rest } = props;

  const isEditLineItem = !!lineItemId;

  const [isLinkMaterialsSidebarOpen, setIsLinkMaterialsSidebarOpen] =
    useState(false);
  const [shouldWarnUserOnClose, setShouldWarnUserOnClose] = useState(false);

  useEffect(() => {
    if (!isOpen) {
      setIsLinkMaterialsSidebarOpen(false);
    }
  }, [isOpen]);

  return (
    <>
      <Sidebar
        isOpen={isOpen}
        size="lg"
        title={isEditLineItem ? 'Edit Item' : 'Create Item'}
        behind={isLinkMaterialsSidebarOpen}
        onClose={!isLinkMaterialsSidebarOpen ? onClose : undefined}
        warnUserOnClose={shouldWarnUserOnClose}
        warnUserContent={{
          body: 'The changes to this Item are not saved and will be lost.',
          confirm: 'Discard changes',
        }}
        {...rest}
      >
        <Sidebar.Body>
          <AddEditItemSidebarContent
            lineItemId={lineItemId}
            isLinkMaterialsSidebarOpen={isLinkMaterialsSidebarOpen}
            setIsLinkMaterialSidebarOpen={setIsLinkMaterialsSidebarOpen}
            setIsDirty={setShouldWarnUserOnClose}
            onSaveSuccess={onClose}
          />
        </Sidebar.Body>
      </Sidebar>
    </>
  );
};

export default AddEditItemSidebar;
