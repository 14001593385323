import React, { FC } from 'react';
import Card, { CardSizeVariant } from '../plCard/Card';
import { CurrencyValue as TCurrencyValue } from '@payaca/types/currencyTypes';
import CurrencyValue from '../currencyValue/CurrencyValue';
import IncrementableNumberInput from '../plInput/IncrementableNumberInput';
import UntitledIcon from '@payaca/untitled-icons';
import EclipseBadge from '../plBadge/EclipseBadge';
import ConditionalWrapper from '../conditionalWrapper/ConditionalWrapper';
import CardButton from '../plCard/CardButton';

type OwnProps = {
  name: string;
  price?: TCurrencyValue;
  suppliers?: string[];
  thumbnailUrl?: string;
};

export type MaterialCardWithQuantityProps = OwnProps & {
  quantity: number;
  onChangeQuantity: (quantity: number) => void;
};

export type MaterialCardProps =
  | OwnProps
  | (OwnProps & {
      actionOnClick: () => void;
      actionDisabled?: boolean;
    })
  | MaterialCardWithQuantityProps;

const MaterialCard: FC<MaterialCardProps> = (props) => {
  const { name, price, thumbnailUrl } = props;

  const wrapperProps = {
    sizeVariant: CardSizeVariant.SM,
    onClick: 'actionOnClick' in props ? props.actionOnClick : undefined,
    disabled: 'actionDisabled' in props ? props.actionDisabled : undefined,
  };

  return (
    <ConditionalWrapper
      condition={'actionOnClick' in props}
      Wrapper={CardButton}
      FallbackWrapper={Card}
      wrapperProps={wrapperProps}
      fallbackWrapperProps={wrapperProps}
    >
      <Card.Body>
        <div className="flex gap-3.5">
          <div className="w-14">
            {thumbnailUrl ? (
              <img
                className="overflow-hidden rounded"
                src={thumbnailUrl}
                alt={name}
              />
            ) : (
              <UntitledIcon
                name="image-01.3"
                vectorEffect="non-scaling-stroke"
                className="h-10 w-10 text-gray-700"
              />
            )}
          </div>

          <div className="flex flex-auto flex-col gap-3.5">
            <div className="flex justify-between gap-3.5">
              <h4>{name}</h4>

              {price ? <CurrencyValue {...price} /> : '-'}
            </div>

            <div className="flex flex-wrap items-center justify-between">
              <div>
                <EclipseBadge
                  badges={props.suppliers || []}
                  variant="soft"
                  colour="gray"
                />
              </div>

              {'quantity' in props && (
                <IncrementableNumberInput
                  wrapperClassName="max-w-32"
                  value={props.quantity}
                  onChange={props.onChangeQuantity}
                  min={0}
                />
              )}
            </div>
          </div>
        </div>
      </Card.Body>
    </ConditionalWrapper>
  );
};

export default MaterialCard;
